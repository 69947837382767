/* ============ Normilize ============*/
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button, input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

textarea {
  overflow: auto; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

/* ============ // Normilize ============*/
/* ============ Reset ============*/
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

h1, h2, h3, h4, h5, h6,
div, p, span, pre, iframe,
a, address, img, strong,
i, ul, li, figcaption, figure, form, fieldset, label, textarea,
input, button, table, thead,
tbody, tr, th, td, canvas, header,
section, aside, footer, audio, video, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inherit;
  color: inherit; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

a {
  text-decoration: none; }

ul {
  list-style-type: none; }

table {
  border-collapse: collapse; }

textarea {
  resize: none; }

textarea,
input {
  outline: none; }

input::-ms-clear {
  display: none; }

button {
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border: none; }

fieldset {
  border: none; }

legend {
  padding: 0; }

section {
  position: relative; }

img {
  max-width: 100%; }

/* ============ // reset ============*/
@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto.woff2") format("woff2");
  src: url("../../fonts/Roboto/Roboto.woff") format("woff");
  font-weight: 400;
  font-style: "normal"; }

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/RobotoMedium/RobotoMedium.woff2") format("woff2");
  src: url("../../fonts/RobotoMedium/RobotoMedium.woff") format("woff");
  font-weight: 500;
  font-style: "normal"; }

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/RobotoBold/RobotoBold.woff2") format("woff2");
  src: url("../../fonts/RobotoBold/RobotoBold.woff") format("woff");
  font-weight: 700;
  font-style: "normal"; }

@table : @media (max-width: 768px);
body {
  display: flex; }

#root {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  font-family: Roboto, sans-serif;
  background: #fff;
  padding-top: 160px; }

main {
  flex-grow: 1; }

.container {
  max-width: 1146px;
  margin: 0 auto; }

@media (max-width: 1146px) {
  .container {
    width: 639px; } }

@media (max-width: 768px) {
  #root {
    padding-top: 120px; }
  .container {
    width: calc(100% - 60px); } }

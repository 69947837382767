body {
	display: flex; }

#root {
	display: flex;
	flex-direction: column;
	width: 100vw;
	min-height: 100vh;
	font-family: Roboto, sans-serif;
	background: #fff;
	padding-top: 160px; }

main {
	flex-grow: 1; }

.container {
	max-width: 1146px;
	margin: 0 auto; }

@media (max-width: 1146px) {
	.container {
		width: 639px; } }

@media (max-width: 768px) {
	#root {
		padding-top: 120px; }
	.container {
		width: calc(100% - 60px); } }
